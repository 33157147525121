import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';


export default function StorePage() {

    return (
        <>
        <Helmet>
            <title>Boutique - Faramareen Music Group</title>
            <meta name='description' content="Site officiel de Faramareen Music Group. Une maison de production musicale indépendante et globalement reconnue."></meta>
            <meta name='keywords' content="Music, Audio, Faramareen, Wally, Seck"></meta>
        </Helmet>

        <Paper id="store" variant="outlined" sx={{ width: '100vw', minHeight: '100vh', border: 0, alignItems: 'center', display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: 'black', }}>
            <Box>
                <Typography sx={{ typography: { xs: 'body1', md: 'h5' }, letterSpacing: { xs: '.2vw', md: '.2vw' }, fontFamily: { xs: 'KaushanScript', md: 'KaushanScript' }, }}  color="#fff" align='center' uk-scrollspy="cls: uk-animation-fade; delay: 100">
                    Aucun article disponible pour le moment.
                    <br />
                    <br />
                    A bientôt.
                </Typography>
            </Box>
        </Paper>
        </>
    );
}