import { Box, Container, Typography } from '@mui/material';
import React from 'react';





const Footer = () => {
    
    

    return (
        <Box sx={{ backgroundColor: '#111', position: 'relative' }}>
            <Container>
                <Typography letterSpacing=".2vw" py={0.5} variant="body2" align="center" color="lightgray" fontFamily="Roboto" uk-scrollspy="cls: uk-animation-fade; delay: 100" >
                    © Faramareen Music Productions 2022
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
