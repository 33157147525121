
import './App.css';
import { Outlet } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";
import ResponsiveAppBar from './components/Appbar';
import Footer from './components/Footer';



function App() {
    return (
        <div className="App">
            
            <ResponsiveAppBar/>

            <ScrollRestoration getKey={(location) => {
                const paths = [];
                return paths.includes(location.pathname)
                    ? // home and notifications restore by pathname
                    location.pathname
                    : // everything else by location like the browser
                    location.key;
            }}
            />

            <Outlet />

            <Footer />

        </div>
    );
}

export default App;
