import Makofnatlov from "./imgOthers/makofnatlov.png";
import Tpmp from "./imgOthers/tpmp.png";
import Makofdmuc from "./imgOthers/makofdmuc.png";


const Others = [

    {
        title: "Making Of Natural Love",
        embedID: "jFSM4ddKUoQ",
        image: Makofnatlov,
    },

    {
        title: "Touche Pas Mon Poste",
        embedID: "sjsa1x3-ffc",
        image: Tpmp,
    },

    {
        title: "Making Of Donne Moi Une Chance",
        embedID: "dnyu72xj0TQ",
        image: Makofdmuc,
    },
        
]

export default Others;