import * as React from 'react';
import { useState } from "react";
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
import { Box } from '@mui/system';

import '../assets/kit/fonts/Lost in Wild Regular.ttf';



const SingleOther = (props) => {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const YoutubeEmbed = ({ embedId }) => (
        <iframe
            src={`https://www.youtube.com/embed/${embedId}?autoplay=1&;enablejsapi=1`}
            frameBorder="0"
            width="100%"
            height="100%"
            muted="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />

  );

    return (
        <Box sx={{  }}>
            <Card component={Button} onClick={() => handleClickOpen()} className="uk-inline-clip uk-transition-toggle" sx={{ borderRadius: 0, width: '100%', p:0 }}>
                <CardCover sx={{ boxShadow: 'none', borderRadius: 0 }}>
                    <img src={props.image} className="uk-transition-scale-up uk-transition-opaque uk-transition-slow" alt="" />
                </CardCover>
                <CardContent sx={{ justifyContent: 'center', background: 'rgba(0,0,0,0.3)', width: '100%' }}>
                    <Typography variant="h5" color="white" align="center" mb={{ xs: 6, sm: 8, md: 5, }} mt={{ xs: 6, sm: 8, md: 23, fontFamily: 'KaushanScript', letterSpacing: '.1rem' }} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 900;">
                        {`${props.title}`}
                    </Typography>
                </CardContent>
            </Card>
            <Dialog fullScreen open={open} onClose={handleClose} sx={{ background: 'black' }} >
                
                <Box sx={{ background: 'black', height: '100vh', width: '100vw' }}>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', bottom: '3%', left: '50%', transform: 'translate(-50%)' }}>
                        <CloseIcon sx={{ color: 'rgba(255,255,255,.3)', fontSize:'3rem', transition: 'color 1s', "&:hover": { color: 'white' } }} />
                    </IconButton>
                    <DialogContent >
                        <Box sx={{ position: 'absolute', width: {xs: '85%', sm: '85%', md: '85%', lg: '80%'}, height: {xs: '45%', sm: '45%', md: '55%', lg: '75%'}, overflow: 'hidden', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                            <YoutubeEmbed embedId={props.embedID} />
                        </Box>
                    </DialogContent>
                </Box>
                
            </Dialog>
        </Box>
    );
};

export default SingleOther;