import Casablanca from "./imShows/casablanca.png";
import Bambey from "./imShows/bambey.png";
import Saloum from "./imShows/saloum.png";


const Shows = [
    {
        place: "Casablanca",
        artist: "Wally B. Seck",
        date: "06/08/2022",
        embedID: "pv37WkhoAyA",
        image: Casablanca,
    },

    {
        place: "Bambey",
        artist: "Wally B. Seck",
        date: "18/07/2022",
        embedID: "Eq8tdRd93uA",
        image: Bambey,
    },

    {
        place: "Saloum",
        artist: "Wally B. Seck",
        date: "12/07/2022",
        embedID: "BtQz54vagQk",
        image: Saloum,
    },

    
        
]

export default Shows;