import { Paper, Typography, Container, Button, Link } from '@mui/material';
import { Box,  } from '@mui/system';
import * as React from 'react';
import Waly from "../assets/images/waly1.jpg";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/KeyboardBackspace';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import OffImg from "../assets/images/art1.jpg";
import FacebookOutlinedIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import ImageList from '@mui/material/ImageList';
import Art3 from "../assets/images/waly2.jpg";
import Art2 from "../assets/images/art3.jpg";
import Art4 from "../assets/images/waly3.jpg";
import Art5 from "../assets/images/waly4.jpg";
import Art7 from "../assets/images/waly5.jpg";
import Art8 from "../assets/images/waly6.jpg";
import Art9 from "../assets/images/waly7.jpg";
import Art12 from "../assets/images/waly8.jpg";
import Art11 from "../assets/images/waly9.jpg";
import Art10 from "../assets/images/waly10.jpg";
import Art13 from "../assets/images/waly11.jpg";
import Art6 from "../assets/images/unused/bg9.jpeg";
import SingleArtistPhoto from '../components/SingleArtistPhoto';
import { Helmet } from 'react-helmet-async';



const ArtistPage = () => {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };    



    const itemData = [
        {
            img: Art3,
            title: 'Breakfast',
            rows: 2,
            cols: 2,
        },
        {
            img: Art2,
            title: 'Burger',
        },
        {
            img: Art4,
            title: 'Camera',
        },
        {
            img: Art5,
            title: 'Coffee',
            cols: 2,
        },
        {
            img: Art6,
            title: 'Hats',
            cols: 2,
        },
        {
            img: Art7,
            title: 'Honey',
            author: '@arwinneil',
            rows: 2,
            cols: 2,
        },
        {
            img: Art8,
            title: 'Basketball',
        },
        {
            img: Art9,
            title: 'Fern',
        },
        {
            img: Art10,
            title: 'Mushrooms',
            rows: 2,
            cols: 2,
        },
        {
            img: Art11,
            title: 'Tomato basil',
        },
        {
            img: Art12,
            title: 'Sea star',
        },
        {
            img: Art13,
            title: 'Bike',
            cols: 2,
        },
    ];
      

    return (

        <>

        <Helmet>
            <title>Artistes - Faramareen Music Group</title>
            <meta name='description' content="Site officiel de Faramareen Music Group. Une maison de production musicale indépendante et globalement reconnue."></meta>
            <meta name='keywords' content="Music, Audio, Faramareen, Wally, Seck"></meta>
        </Helmet>

        <Paper>

            <Box sx={{ textDecoration: 'none', p: 0,  }}>
                <Paper id="Wally" variant="outlined" square sx={{ backgroundImage: `url(${Waly})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', width: '100vw', height: '100vh', overflow: 'hidden', border: 0 }}>
                    
                    <Box sx={{ width: '100vw', height: '100vh', background: 'rgba(0,0,0,0.3)', alignItems: 'left', display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Container sx={{ background: 'transparent', maxWidth: { xs:"xs", sm:"sm", md:"md", lg:"lg", xl:"xl" } }}>
                            <Box sx={{ width: { xs: "100%", lg: "30%" } }}>
                                <Typography variant="h4" align="center" sx={{ color: 'white', fontFamily: 'KaushanScript', }} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 200;">
                                    Wally Ballago Seck
                                </Typography>
                                <Typography variant="body1" align="center" sx={{ color: 'white', fontFamily: 'Roboto', mt:1, }} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 400;">
                                    Auteur - Compositeur
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt:3 }}>
                                    <Button sx={{ fontFamily: 'Roboto', button: { xs: 'small', md: 'medium' }, backgroundColor: 'white', border: '1px solid', borderColor: 'white', borderRadius: '0px', color: 'black', justifyContent: 'flex-end', "&:hover": { color: 'white' } }} uk-scrollspy="cls: uk-animation-slide-bottom-small; delay: 1000;" onClick={() => handleClickOpen()}>
                                        Découvrir
                                    </Button>
                                </Box>
                            </Box>
                            
                        </Container>
                    </Box>
                    
                </Paper>
            </Box>

            <Dialog fullScreen open={open} onClose={handleClose} sx={{  }}>
                
                <DialogContent sx={{ p:0, backgroundImage: `url(${Waly})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', position: 'relative' }} >
                
                    <CardCover sx={{ background: 'rgba(0,0,0,.85)', position: 'fixed' }} />
                    
                    <Box sx={{ position: 'relative', pb: 7 }}>
                    
                        <IconButton onClick={handleClose} sx={{ position: 'fixed', left: '0%', pl:3 }} >
                            <CloseIcon sx={{ color: 'rgba(255,255,255,.3)', fontSize:'3.5rem', transition: 'color 1s', "&:hover": { color: 'white' } }} />
                        </IconButton>
                        

                        <Container sx={{ background: 'transparent', pt:10, maxWidth: { xs:"xs", sm:"sm", md:"md", lg:"lg", xl:"xl" } }}>
                            <Grid container>
                                <Grid xs={12} md={5} sx={{ background: 'linear-gradient(to right, rgb(210, 172, 71), rgba(0,0,0,0) 30%)', p:5 }} item>
                                    <Card sx={{ height: { xs: '40vh', md: '60vh', }, borderRadius: 0 }}>
                                        <CardCover sx={{ boxShadow: 'none', borderRadius: 0 }}>
                                            <img src={OffImg} alt="" className='imgSt' />
                                        </CardCover>
                                    </Card> 
                                </Grid>

                                <Grid xs={12} md={7} item>
                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", height: '100%' }}>
                                        <Box sx={{ borderTop: "5px solid lightgray ", ml: {xs: 5, md: 10}, mr: {xs: 10, md: 25}, mt: 3, display: 'none'  }} uk-scrollspy="cls: uk-animation-slide-right-small; delay: 300;"></Box>
                                        <Box sx={{  ml: {xs: 0, md:10}, pt: 4, }} uk-scrollspy="cls: uk-animation-slide-right-small; delay: 300;">
                                            <Typography variant="body1" fontFamily="Roboto" fontStyle="italic" color="white">
                                                Issu d'une famille de griot, Wally Seck a grandi dans la capitale sénégalaise. Dans sa jeunesse, il s'est trouvé une passion pour le football et rêvait d'une carrière professionnelle dans ce domaine. Il a même joué dans la primevera.
                                                Cependant, sa carrière peinant à décoller, il retourna au Sénégal pour se concentrer dans sa carrière musicale. Il commence sa carrière avec le single Bo - Dioudo en 2007 qui fût un succès au Sénégal et dans la diaspora.
                                                <br />
                                                <br />
                                                Il a sorti plusieurs albums à succès, tels que "Xel" (2010), "Djiguène" (2013), "Génération consciente" (2014), "Lionne" (2018) et bien d'autres. Il est connu pour son style musical varié, qui mélange des influences de musique afro-pop, mbalax (genre musical sénégalais) et d'autres rythmes africains. 
                                                Il est également réputé pour ses performances scéniques énergiques et sa voix puissante. Ses chansons abordent souvent des thèmes sociaux, sentimentaux et culturels, et il est apprécié pour sa capacité à toucher et à divertir son public.
                                                <br />
                                                <br />
                                                En plus de sa carrière musicale, Waly Seck est également très présent sur les réseaux sociaux, où il compte un grand nombre de fans et d'abonnés. Il continue d'attirer l'attention et de faire parler de lui dans l'industrie musicale sénégalaise et au-delà.
                                            </Typography>
                                        </Box>
                                        <br />

                                        <Box sx={{ mt:2 }} align='right' color='white'>
                                            <Link sx={{ textDecoration: 'none' }} target='_blank' href='https://www.instagram.com/wallyseckofficiel/?hl=en'>
                                                <InstagramIcon sx={{ m:.5, color: 'white' }} uk-scrollspy="cls: uk-animation-slide-right-small; delay: 500;"/>
                                            </Link>
                                            <Link sx={{ textDecoration: 'none' }} target='_blank' href='https://twitter.com/wallyseck27?lang=en'>
                                                <TwitterIcon sx={{ m:.5, color: 'white' }} uk-scrollspy="cls: uk-animation-slide-right-small; delay: 500;"/>
                                            </Link>
                                            <Link sx={{ textDecoration: 'none' }} target='_blank' href='https://www.facebook.com/WallySeckOfficiel/'>
                                                <FacebookOutlinedIcon sx={{ m:.5, color: 'white' }} uk-scrollspy="cls: uk-animation-slide-right-small; delay: 500;"/>
                                            </Link>
                                            
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                            <ImageList
                                sx={{ mt: 10, }}
                                variant="quilted"
                                cols={4}
                            >
                                {itemData.map(({title, img, rows, cols}, index) => (

                                    <SingleArtistPhoto title={title} img={img} rows={rows} cols={cols} key={index}/>

                                ))}
                            </ImageList>


                        </Container>
                        
                    </Box>
                </DialogContent>
            
            </Dialog>





        </Paper>
        </>
    );
};

export default ArtistPage;