
import Off from "../assets/logo/offLogo.png";
import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import UpperBar from "./UpperBar";
import { NavLink, Link } from "react-router-dom";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import { Drawer } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';





const drawerWidth = 240;


function HideOnScroll(props) {

    const { children, window } = props;


    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

  
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


const ResponsiveAppBar = (props) => {

    const navColor = "linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0)), linear-gradient(to bottom, rgba(0,0,0,.7), rgba(0,0,0,0) )";
    const linkColor = "white";
    const primaryColor = "#d2ac47";
    const oPrimaryColor = "rgba(210, 172, 71, .6)";
    const logo = Off;
    
    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;
    
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const pages = [
        { id: 1, title: "Artistes", to: "/artists" },
        { id: 2, title: "Services", to: "/services" },
        { id: 3, title: "Videos", to: "/videos" },
        { id: 4, title: "Boutique", to: "/store" }
    ]


    
    return (

        <HideOnScroll {...props} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 800">
        
        <AppBar position="fixed" sx={{ background: navColor, boxShadow: 'none',  transition: "all 1s", }} uk-scrollspy="cls: uk-animation-slide-top-medium; delay: 1300;">
            
            <UpperBar />
            <Container sx={{ maxWidth: {  lg:"lg", xl:"xl" } }}>
                <Toolbar disableGutters>

                    <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, py: .5 }} justifyContent="space-between">

                        <Box sx={{ display: 'flex', flexGrow: 1 }}  justifyContent="space-evenly">

                            <Button component={NavLink}  to="/artists" sx={{ my: 2, color: linkColor, transition: "all 1s", display: 'block', fontWeight: 'bold', fontFamily:'Roboto', letterSpacing:'.1rem' }}>
                                Artistes
                            </Button>

                            <Button component={NavLink}  to="/services" sx={{ my: 2, color: linkColor, transition: "all 1s", display: 'block', fontWeight: 'bold', fontFamily:'Roboto', letterSpacing:'.1rem' }}>
                                Services
                            </Button>                            

                        </Box>


                        <Link to="/">
                            <Box component="img" src={logo} sx={{ maxHeight: '80px', position: 'absolute', left: '50%', transform:'translate(-50%)' }} />
                        </Link>

                        <Box sx={{ display: 'flex', flexGrow: 1  }} justifyContent="space-evenly">

                            <Button component={NavLink} to="/videos" sx={{ my: 2, color: linkColor, transition: "all 1s", display: 'block', fontWeight: 'bold', fontFamily:'Roboto', letterSpacing:'.1rem' }}>
                                Videos
                            </Button>
                            

                            <Button component={NavLink} to="/store" sx={{ my: 2, color: linkColor, transition: "all 1s", display: 'block', fontWeight: 'bold', fontFamily:'Roboto', letterSpacing:'.1rem' }}>
                                Boutique
                            </Button>

                        </Box>

                        

                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} justifyContent="space-between" alignItems="center">

                        <Link to="/">
                            <Box component="img" src={logo} sx={{ maxHeight: '70px', background: 'rgba()' }} />
                        </Link>
                        
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Drawer container={container} anchor="left" variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} sx={{ display: { xs: 'block', md: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: 'black' } }}>
                            <Box component="img" loading="lazy" src={logo} sx={{ width: 1, px:7, py:2 }} alt="Little Happy Bliss" />
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height:1 }} onClick={handleDrawerToggle}>
                                {pages.map(( page ) => (
                                    <Button component={Link} to={ page.to } key={page.id} sx={{ py: 1, fontFamily: 'Saudagar', color: '#d2ac47', borderRadius:'0px', width:1, fontWeight: 'bold', '&:hover': {color: oPrimaryColor, backgroundColor: 'transparent'} }}
                                >
                                    {page.title}
                                </Button>
                                ))}
                            </Box>

                            
                            <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, justifyContent: 'center', py:2 }}>
                                
                                <IconButton component={Link} href="" sx={{ color: primaryColor, '&:hover': { backgroundColor: 'transparent', color: oPrimaryColor } }}>
                                    <FacebookIcon fontSize='small' />
                                </IconButton>
                                <IconButton component={Link} href="" sx={{ color: primaryColor, '&:hover': { backgroundColor: 'transparent', color: oPrimaryColor } }}>
                                    <InstagramIcon fontSize='small' />
                                </IconButton>
                                <IconButton component={Link} href="" sx={{ color: primaryColor, '&:hover': { backgroundColor: 'transparent', color: oPrimaryColor } }}>
                                    <TwitterIcon fontSize='small' />
                                </IconButton>
                                <IconButton component={Link} href="" sx={{ color: primaryColor, '&:hover': { backgroundColor: 'transparent', color: oPrimaryColor } }}>
                                    <MailOutlineIcon fontSize='small' />
                                </IconButton>
                                
                            </Box>
                        </Drawer>
                        
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>

        </HideOnScroll>
    );
};
export default ResponsiveAppBar;
