import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/system';
import Button from '@mui/material/Button';
import '../assets/kit/css/uikit.min.css';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardOverflow from '@mui/joy/CardOverflow';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/material/Typography';
import '../assets/kit/fonts/Lost in Wild Regular.ttf';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Eclairage from "../assets/services/sono3.jpeg";
import Strsc from "../assets/services/stsc1.jpg";
import Cont from "../assets/services/cont2.gif";
import Dg1 from "../assets/services/dg1.jpeg";
import Prestation from "../assets/services/bg5.jpg";
import OffImg from "../assets/services/off.jpeg";
import Fans from "../assets/services/tsc1.jpg";
import { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async'


export default function ServicePage() {

    const anim1 = "uk-animation-reverse uk-transform-origin-top-bottom";
    const anim2 = "uk-animation-reverse uk-transform-origin-center-center";
    const anim3 = "uk-animation-reverse uk-transform-origin-top-right";

    const ref = useRef(null);

    const CssTextField = styled(TextField)({
        '& label': {
            color: 'black',
            fontFamily: 'Roboto',
        },
        '& label.Mui-focused': {
            color: '#d2ac47',
            fontWeight: 'bold',
            fontFamily: 'Roboto',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#d2ac47',
        },
        
    });

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };


    return (
        <>

        <Helmet>
            <title>Services - Faramareen Music Group</title>
            <meta name='description' content="Site officiel de Faramareen Music Group. Une maison de production musicale indépendante et globalement reconnue."></meta>
            <meta name='keywords' content="Music, Audio, Faramareen, Wally, Seck"></meta>
        </Helmet>
        
        <Paper id="services" variant="outlined" sx={{ width: '100vw', height: 'auto', border: 0, justifyContent: 'center', alignItems: 'center' }}>

            <Box uk-scrollspy="cls: uk-animation-fade; delay: 200">
                <Card sx={{ boxShadow: 'none', borderRadius: 0, }}>

                    <CardCover className="uk-overflow-hidden" sx={{ boxShadow: 'none', borderRadius: 0 }} >
                        <img className={anim1} uk-scrollspy="cls: uk-animation-kenburns; repeat: true" src={OffImg} alt="" />
                    </CardCover>

                    <CardCover sx={{ background: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0) )', borderRadius: 0, }} />

                    <CardContent sx={{ m: 0, }}>

                        <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" } }}>
                            <Box sx={{ minHeight: { xs: '50vh', sm: '50vh', md: '50vh', lg: '70vh' }, display: "flex", flexDirection: "column", justifyContent: "center", }}>
                                <Box sx={{ typography: { xs: "subtitle1", md: "h4" }, fontWeight: { xs: "bold", md: "bold" }, textTransform: { xs: "none", md: "none" }, fontFamily: { xs: "KaushanScript", md: "KaushanScript" } }} color="white" align='left'  uk-scrollspy="cls: uk-animation-slide-left-small; delay: 900;">
                                    La passion du son,
                                    <Box className='uk-visible@xs' sx={{ pt:1 }} />
                                    La magie de la création.
                                    <Box className='uk-visible@xs' sx={{ pt:1 }} />
                                    Notre maison de production à votre service.
                                </Box>
                                
                                
                                <br />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button sx={{ button: { xs: 'small', md: 'medium' }, fontFamily: 'Roboto', backgroundColor: 'white', border: '1px solid', borderColor: 'white', borderRadius: '0px', color: 'black', justifyContent: 'flex-end', "&:hover": { color: 'white' } }} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 1100;" onClick={handleClick}>Nos Services</Button>
                                </Box>

                            </Box>
                        </Container>

                    </CardContent>
                </Card>
            </Box>

            <Box sx={{ backgroundColor: 'rgba(210, 172, 71,.1)' }}>
                <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" } }}>

                    <Card uk-scrollspy="cls: uk-animation-slide-top-small; delay: 200" sx={{ borderRadius: 0, display: "flex", flexDirection: "column", justifyContent: "center", pt: 0, pb: {xs: 0, md: 0}, pl: 0, pr: 0, mt:{xs: -12, md: -10, lg: -20} }}>

                        <Paper elevation={6} square sx={{ }}>
                            <Grid container>
                                
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ p: { xs: 2, md: 5.9 }, }} uk-scrollspy="cls: uk-animation-slide-right-small; delay: 900;">
                                        <br />
                                        <Typography variant="subtitle1" fontFamily="Roboto">
                                            <Typography variant='h6' sx={{ pr:.3, fontFamily: 'KaushanScript', color: '#d2ac47', display: 'inline-block' }}>N</Typography>ous sommes une équipe passionnée, dévouée à l'art et à la création musicale. Que vous soyez un artiste émergent ou établi, notre objectif est de vous accompagner dans votre parcours artistique, en vous offrant les outils et les ressources nécessaires pour faire briller votre talent.
                                            <br />
                                            <br />
                                                Notre maison de production est un lieu où les idées prennent vie, où les mélodies se transforment en véritables chefs-d'œuvre et où les artistes peuvent exprimer leur vision artistique de la manière la plus authentique qui soit. Nous croyons en la puissance de la musique pour toucher les cœurs, inspirer et créer des moments inoubliables.                                        </Typography>
                                            <br />
                                            <Typography align="right" fontWeight="bold" fontFamily="Roboto">Mohamed Seck</Typography>
                                            <Typography align="right" fontFamily="Roboto">Directeur</Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6} sx={{ display: {xs: 'block', md: 'block'} }}>
                                    <Card sx={{ height: { xs: '40vh', md: '65vh' }, }}>
                                        <CardCover sx={{ boxShadow: 'none', borderRadius: 0 }}>
                                            <img src={Dg1} alt="" />
                                            <CardCover sx={{ background: { xs: 'linear-gradient(to top, rgba(0,0,0,.4), rgba(0,0,0,0)) 50% , linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0) 100px)', md: 'linear-gradient(to left, rgba(0,0,0,.4), rgba(0,0,0,0)) 50% , linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0) 300px)' }, borderRadius: 0, }} />
                                        </CardCover>
                                    </Card>
                                </Grid>

                            </Grid>
                        </Paper>

                    </Card>

                </Container>
            </Box>

            <Box ref={ref} sx={{ pt: 17, pb: 10, background: 'linear-gradient(to bottom, rgba(210, 172, 71,.1), black)' }}>
                <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" }, }}>

                    <Typography sx={{ typography: {xs: 'h4', md: 'h3'}, fontFamily: {xs: 'Roboto', md: 'Roboto'}, fontWeight: {xs: 'bold', md: 'bold'}, }} color="#000" align="center"  textTransform='uppercase' pb={5} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 600; offset-top: 100">Services</Typography>

                    <Grid container spacing={3} >

                        <Grid xs={12} md={4} item>
                            <Card sx={{ borderRadius: 0, height: '100%', p:0 }} uk-parallax="y: -200,0; end: 60vh + 50%; easing: -2; media: @m">
                                <CardOverflow >
                                    <AspectRatio ratio="1.5" className="uk-overflow-hidden">
                                        <img
                                            src={Prestation}
                                            className={anim1}
                                            uk-scrollspy="cls: uk-animation-kenburns; repeat: true"
                                            alt="Prestation"
                                        />
                                    </AspectRatio>
                                </CardOverflow>
                                <Box sx={{ p: 2 }} >
                                    <Typography variant="subtitle1" sx={{ mt: 1 }} textTransform="uppercase" color="#d2ac47" fontFamily="Roboto" fontWeight="bold" uk-scrollspy="cls: uk-animation-slide-left-small; delay: 400;">
                                        Gestion artistique
                                    </Typography>
                                    <Typography variant="body1" fontFamily="Roboto" sx={{ mt: 0.5, mb: 2 }} uk-scrollspy="cls: uk-animation-fade; delay: 600;">
                                        Nous assurons la gestion globale de la carrière des artistes, en les aidant à définir leur identité artistique, à développer leur image, à planifier leur parcours musical et à prendre des décisions stratégiques pour favoriser leur croissance.
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>

                        <Grid xs={12} md={4} item>
                            <Card sx={{ borderRadius:0, height: '100%', p:0 }} uk-parallax="y: -200,0; end: 60vh + 50%; easing: 0; media: @m">
                                <CardOverflow >
                                    <AspectRatio ratio="1.5" className="uk-overflow-hidden">
                                        <img
                                            src={Strsc}
                                            className={anim2}
                                            uk-scrollspy="cls: uk-animation-kenburns; repeat: true"
                                            alt=""
                                        />
                                    </AspectRatio>
                                </CardOverflow>
                                <Box sx={{ p: 2, height:1 }} >
                                    <Typography variant="subtitle1" sx={{ mt: 1 }} textTransform="uppercase" color="#d2ac47" fontFamily="Roboto" fontWeight="bold" uk-scrollspy="cls: uk-animation-slide-left-small; delay: 400;">
                                        Gestion des spectacles
                                    </Typography>
                                    <Typography variant="body1" fontFamily="Roboto" sx={{ mt: 0.5, mb: 2 }} uk-scrollspy="cls: uk-animation-fade; delay: 600;">
                                        Nous nous occupons de la réservation de concerts et de spectacles pour les artistes, en négociant les contrats, en coordonnant les tournées et en veillant à ce que les artistes disposent des ressources nécessaires pour offrir des performances de qualité.
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>

                        <Grid xs={12} md={4} item>
                            <Card sx={{ borderRadius: 0, height: 1, p:0 }} uk-parallax="y: -200,0; end: 60vh + 50%; easing: 2; media: @m">
                                <CardOverflow >
                                    <AspectRatio ratio="1.5" className="uk-overflow-hidden">
                                        <img
                                            src={Eclairage}
                                            className={anim3}
                                            uk-scrollspy="cls: uk-animation-kenburns; repeat: true"
                                            alt=""
                                        />
                                    </AspectRatio>
                                </CardOverflow>
                                <Box sx={{ p: 2 }} >
                                    <Typography variant="subtitle1" sx={{ mt: 1 }} textTransform="uppercase" color="#d2ac47" fontFamily="Roboto" fontWeight="bold" uk-scrollspy="cls: uk-animation-slide-left-small; delay: 400;">
                                        Enregistrement et production
                                    </Typography>
                                    <Typography variant="body1" fontFamily="Roboto" sx={{ mt: 0.5, mb: 2 }} uk-scrollspy="cls: uk-animation-fade; delay: 600;">
                                        Nous assurons la distribution de la musique sur différentes plateformes de streaming, de téléchargement et de vente physique. Nous nous occupons des démarches administratives, de la gestion des métadonnées et des droits d'auteur, afin que la musique des artistes soit disponible et accessible à un large public.
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>

                        <Grid xs={12} md={6} item>
                            <Paper elevation={6} uk-parallax="y: -200,0; end: 60vh + 50%; easing: -2; media: @m; opacity: 0,0,0,1" sx={{  }}>
                                <Card sx={{ borderRadius: 0 }}>
                                    
                                    <Box sx={{ p: 2 }} >
                                        <Typography variant="subtitle1" sx={{ mt: 1 }} textTransform="uppercase" color="#d2ac47" fontFamily="Roboto" fontWeight="bold" uk-scrollspy="cls: uk-animation-slide-left-small; delay: 400;">
                                            Promotion et marketing
                                        </Typography>
                                        <Typography variant="body1" fontFamily="Roboto" sx={{ mt: 0.5, mb: 2 }} uk-scrollspy="cls: uk-animation-fade; delay: 600;">
                                            Nous élaborons des stratégies de promotion et de marketing pour les artistes, en mettant en place des campagnes de promotion ciblées, en créant du contenu visuel attrayant, en gérant les réseaux sociaux et en collaborant avec des médias et des influenceurs pour accroître la visibilité des artistes.
                                        </Typography>
                                    </Box>
                                </Card>
                            </Paper>
                        </Grid>

                        
                        <Grid xs={12} md={6} item>
                            <Paper elevation={6} uk-parallax="y: -200,0; end: 60vh + 50%; easing: -2; media: @m; opacity: 0,0,0,1">
                                <Card sx={{ borderRadius: 0 }}>
                                    
                                    <Box sx={{ p: 2 }} >
                                        <Typography variant="subtitle1" sx={{ mt: 1 }} textTransform="uppercase" color="#d2ac47" fontFamily="Roboto" fontWeight="bold" uk-scrollspy="cls: uk-animation-slide-left-small; delay: 400;">
                                            Distribution numérique et physique
                                        </Typography>
                                        <Typography variant="body1" fontFamily="Roboto" sx={{ mt: 0.5, mb: 2 }} uk-scrollspy="cls: uk-animation-fade; delay: 600;">
                                            Nous assurons la gestion globale de la carrière des artistes, en les aidant à définir leur identité artistique, à développer leur image, à planifier leur parcours musical et à prendre des décisions stratégiques pour favoriser leur croissance.
                                        </Typography>
                                    </Box>
                                </Card>
                            </Paper>
                        </Grid>
                        
                        <Grid xs={12} md={6} item>
                            <Paper elevation={6} uk-parallax="y: -200,0; end: 60vh + 50%; easing: -2; media: @m; opacity: 0,0,0,1" sx={{ height: 1 }}>
                                <Card sx={{ borderRadius: 0 }}>
                                    
                                    <Box sx={{ p: 2 }} >
                                        <Typography variant="subtitle1" sx={{ mt: 1 }} textTransform="uppercase" color="#d2ac47" fontFamily="Roboto" fontWeight="bold" uk-scrollspy="cls: uk-animation-slide-left-small; delay: 400;">
                                            Accès aux opportunités
                                        </Typography>
                                        <Typography variant="body1" fontFamily="Roboto" sx={{ mt: 0.5, mb: 2 }} uk-scrollspy="cls: uk-animation-fade; delay: 600;">
                                            En tant que label, nous exploitons notre réseau et nos contacts dans l'industrie musicale pour offrir aux artistes des opportunités telles que des collaborations avec d'autres artistes, des placements dans des films, des publicités ou des séries télévisées, ainsi que des participations à des événements et festivals de renom.
                                        </Typography>
                                    </Box>
                                </Card>
                            </Paper>
                        </Grid>
                        
                        <Grid xs={12} md={6} item>
                            <Paper elevation={6} uk-parallax="y: -200,0; end: 60vh + 50%; easing: -2; media: @m; opacity: 0,0,0,1">
                                <Card sx={{ borderRadius: 0 }}>
                                    
                                    <Box sx={{ p: 2 }} >
                                        <Typography variant="subtitle1" sx={{ mt: 1 }} textTransform="uppercase" color="#d2ac47" fontFamily="Roboto" fontWeight="bold" uk-scrollspy="cls: uk-animation-slide-left-small; delay: 400;">
                                            Développement de fanbase
                                        </Typography>
                                        <Typography variant="body1" fontFamily="Roboto" sx={{ mt: 0.5, mb: 2 }} uk-scrollspy="cls: uk-animation-fade; delay: 600;">
                                            Nous travaillons à la construction et à l'engagement d'une communauté de fans autour des artistes. Cela peut inclure la gestion des newsletters, la création de contenu exclusif, l'organisation de concours, et d'autres initiatives visant à développer une relation solide entre les artistes et leurs fans.
                                        </Typography>
                                    </Box>
                                </Card>
                            </Paper>
                        </Grid>

                        
                    </Grid>

                </Container>
            </Box>

            <Card sx={{ boxShadow: 'none', borderRadius: 0, }}>

                <CardCover className="uk-overflow-hidden" sx={{ boxShadow: 'none', borderRadius: 0 }} >
                    <img className={anim2} uk-scrollspy="cls: uk-animation-kenburns; repeat: true" src={Fans} alt="" />
                </CardCover>

                <CardCover sx={{ background: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0) )', borderRadius: 0, }} />

                <CardContent sx={{ m: 0, }}>

                    <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" }, color: 'white' }}>
                        <Grid container spacing={3} sx={{ minHeight: '40vh', pb: 5 }}>
                            <Grid xs={12} md={4} item sx={{ alignItems: 'center', display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Typography color="#d2ac47" sx={{ display: 'inline-block', m:0, typography: { xs: "h4", md: "h3" }, fontFamily: { xs: "KaushanScript", md: "KaushanScript" }, mb:3 }} uk-scrollspy="cls: uk-animation-slide-left-small; delay: 200;">Notre Parcours</Typography>
                            </Grid>
                            <Grid xs={12} md={8} item>
                                <Grid spacing={3} container sx={{ height: '100%' }} uk-scrollspy="cls: uk-animation-slide-bottom-small; delay: 200;">
                                    <Grid xs={12} md={3} item sx={{ alignItems: 'center', display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <Typography sx={{ typography: {xs: 'h6', md: 'h5'}, fontFamily: {xs: 'Roboto', md: 'Roboto'}, fontWeight: {xs: 'bold', md: 'bold'} }} align="center" color="white" uk-scrollspy="cls: uk-animation-slide-bottom-small; delay: 400;">15<br />Pays</Typography>
                                    </Grid>
                                    <Grid xs={12} md={3} item sx={{ alignItems: 'center', display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <Typography sx={{ typography: {xs: 'h6', md: 'h5'}, fontFamily: {xs: 'Roboto', md: 'Roboto'}, fontWeight: {xs: 'bold', md: 'bold'} }} align="center" color="white" uk-scrollspy="cls: uk-animation-slide-bottom-small; delay: 600;">+ 250<br />Evènements</Typography>
                                    </Grid>
                                    <Grid xs={12} md={3} item sx={{ alignItems: 'center', display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <Typography sx={{ typography: {xs: 'h6', md: 'h5'}, fontFamily: {xs: 'Roboto', md: 'Roboto'}, fontWeight: {xs: 'bold', md: 'bold'} }} align="center" uk-scrollspy="cls: uk-animation-slide-bottom-small; delay: 800;">+ 100<br />Collaborations</Typography>
                                    </Grid>
                                    <Grid xs={12} md={3} item sx={{ alignItems: 'center', display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <Typography sx={{ typography: {xs: 'h6', md: 'h5'}, fontFamily: {xs: 'Roboto', md: 'Roboto'}, fontWeight: {xs: 'bold', md: 'bold'} }} align="center" color="white" uk-scrollspy="cls: uk-animation-slide-bottom-small; delay: 1000;">13<br />Distinctions</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>

                </CardContent>

            </Card>

            <Box sx={{ backgroundColor: 'white'  }}>
                <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" }, pt: {xs: 5, md: 10}, pb: {xs: 5, md: 10}}}>
                    <Grid container spacing={6}>

                        <Grid item xs={12} md={5} sx={{}}>
                            

                            <Card sx={{ height: { xs: '40vh', md: '65vh' }, borderRadius: 0 }}>

                                <CardCover sx={{ boxShadow: 'none', borderRadius: 0 }}>
                                    <img src={Cont} alt="" />
                                    <CardCover sx={{ background: 'rgba(0,0,0,.3)', }} />
                                </CardCover>
                            </Card>
                        </Grid>
                        
                        <Grid item xs={12} md={7}>
                            <Box uk-scrollspy="cls: uk-animation-slide-right-small; delay: 400" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", height: '100%' }}>

                                <Typography variant="h4" fontFamily="Roboto" fontWeight="bold" color="#d2ac47">
                                    Contactez Nous
                                </Typography>
                                
                                <Typography variant="subtitle1" fontFamily="Roboto">
                                    Vous pouvez remplir le formulaire de contact disponible sur notre site internet. Indiquez votre nom, votre adresse e-mail et écrivez votre message ou votre demande dans la zone prévue à cet effet. Nous vous répondrons dans les plus brefs délais.
                                </Typography>
                                <br />
                                <Box sx={{ fontFamily: 'Roboto' }}>
                                    <Grid container spacing={3}>
                                        
                                        <Grid item xs={12} md={6}>
                                            <CssTextField fullWidth id="standard-basic" label="Nom" variant="standard" required />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CssTextField fullWidth id="standard-basic" label="Prénom" variant="standard" required />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CssTextField fullWidth id="standard-basic" label="E-mail" variant="standard" required type="email" />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CssTextField fullWidth id="standard-basic" label="Number" variant="standard" required type="number" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CssTextField fullWidth multiline rows={3} id="standard-basic" label="Message" variant="standard" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button sx={{ fontFamily: 'Roboto', button: { xs: 'small', md: 'medium' }, backgroundColor: '#d2ac47', border: '1px solid', borderColor: 'white', borderRadius: '0px', color: 'white', justifyContent: 'flex-end', "&:hover": { color: '#d2ac47', borderColor: '#d2ac47', } }} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 400;" onClick={handleClick}>Envoyer</Button>
                                            </Box>
                                        </Grid>
                                        
                                        
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

        </Paper>
        </>
    );
}
