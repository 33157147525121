import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { CssBaseline, Typography, Button } from '@mui/material';
import VidBg from "../assets/images/bg8.jpg";
import VidBgSm from "../assets/images/bg8sm.jpg";
import Waly5 from "../assets/videos/Teaser.mp4";
import '../assets/kit/css/uikit.min.css';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SingleClip from "../components/SingleClip";
import SingleShow from "../components/SingleShow";
import SingleOther from "../components/SingleOther";
import Clips from "../components/Videos/Clips.js";
import Shows from "../components/Videos/Shows.js";
import Others from "../components/Videos/Others.js";
import '../assets/kit/fonts/Lost in Wild Regular.ttf';
import SendIcon from '@mui/icons-material/Send';
import { Helmet } from 'react-helmet-async';





export default function VideoPage() {


    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    return (
        <>

            <Helmet>
                <title>Videos - Faramareen Music Group</title>
                <meta name='description' content="Site officiel de Faramareen Music Group. Une maison de production musicale indépendante et globalement reconnue."></meta>
                <meta name='keywords' content="Music, Audio, Faramareen, Wally, Seck"></meta>
            </Helmet>

        <Paper id="videos" variant="outlined" sx={{ width: '100vw', height: 'auto', border: 0, justifyContent: 'center', alignItems: 'center', backgroundImage: {xs: `url(${VidBgSm})`, md: `url(${VidBg})`}, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', overflow: 'hidden' }}>

            <Box sx={{ width: '100vw', height: '100vh', background: 'linear-gradient(to bottom, rgba(255,255,255,0.8), rgba(255,255,255,0) 400px), linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0) 300px)', position: 'fixed' }} />

            <CssBaseline />

            <TabContext value={value} sx={{ width: '100vw', height: '100vh', }}>

                <Box uk-scrollspy="cls: uk-animation-fade; delay: 200" >
                    <Card sx={{ boxShadow: 'none', borderRadius: 0, height: {xs: '50vh', md: '60vh'} }}>
                        <CardCover sx={{ boxShadow: 'none', borderRadius: 0, }}>
                            <video autoPlay playsInline disablePictureInPicture preload="auto" loop muted poster="https://assets.codepen.io/6093409/river.jpg">
                                <source src={Waly5} />
                            </video>
                        </CardCover>
                        <CardContent sx={{ justifyContent: 'center', alignItems: '1', m: 0, }}>
                            <Container sx={{ maxWidth: {  lg:"lg", xl:"xl" } }}>
                                <Grid container>
                                    <Grid item xs={12} md={5}>
                                        <Box uk-parallax="opacity: 1,1,0; y: 0,200; x: 0,200;" sx={{ justifyContent: 'center', width: '100%' }}>
                                            <Typography align='center' color="#d2ac47" sx={{ typography: {xs: 'h6', md: 'h4'}, fontFamily: {xs: 'KaushanScript', md: 'KaushanScript'} }} uk-scrollspy="cls: uk-animation-fade; delay: 900;">Je ne suis pas comme eux</Typography>
                                            <Typography align='center' color="white" sx={{ typography: {xs: 'h6', md: 'h5'}, fontFamily: {xs: 'Roboto', md: 'Roboto'}, fontWeight: {xs: 'bold', md: 'normal'} }} uk-scrollspy="cls: uk-animation-slide-left-small; delay: 1100;">Wally B. Seck</Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                                                <Button href='https://youtu.be/MObySsZo594' target='_blank' align='center' endIcon={<SendIcon />} sx={{ fontFamily: 'Roboto', mt:1, backgroundColor: 'transparent', border: '1px solid', borderColor: '#d2ac47', borderRadius:'0px', color: '#d2ac47' }} uk-scrollspy="cls: uk-animation-slide-left; delay: 1300;">Regarder</Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                        </CardContent>
                        <CardCover sx={{ background: 'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0) )',  borderRadius: 0, }} />
                        <CardContent sx={{ justifyContent: 'center', alignItems: 1, m: 0, bottom: 0, position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                            <TabList centered textColor="inherit" TabIndicatorProps={{ sx: { background: '#d2ac47', mb: 1 } }} onChange={handleChange} aria-label="lab API tabs example" sx={{  color: 'black',  }}>
                                <Tab label="Clips" value="1" sx={{ fontFamily:'Roboto', fontWeight:'bold', letterSpacing:'.1rem', color: 'white' }} />
                                <Tab label="Concerts" value="2" sx={{ fontFamily:'Roboto', fontWeight:'bold', letterSpacing:'.1rem', color: 'white' }} />
                                <Tab label="Autres" value="3" sx={{ fontFamily:'Roboto', fontWeight:'bold', letterSpacing:'.1rem', color: 'white' }} />
                            </TabList>
                        </CardContent>
                    </Card>
                </Box>

                <Container sx={{ background: 'transparent', maxWidth: { lg:"lg", xl:"xl" } }}>

                    <Box sx={{ flexGrow: 1, fontFamily: 'monospace' }}>

                        <TabPanel value="1" sx={{  }}>
                            <Grid container spacing={3} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 200; target: > div;">
                            {
                                Clips.map( ({ title, artist, embedID, image }, index) => 
                                    <Grid item xs={12} md={6} xl={4} key={index}>
                                        <SingleClip title={title} artist={artist} embedID={embedID} image={image} />
                                    </Grid>
                                )
                            }
                            </Grid>
                        </TabPanel>

                        <TabPanel value="2" sx={{ width: '100%' }}>
                            <Grid container spacing={3} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 200; target: > div;">
                            {
                                Shows.map( ({ place, date, artist, embedID, image }, index) => 
                                    <Grid item xs={12} md={6} xl={4} key={index}>

                                        <SingleShow place={place} artist={artist} embedID={embedID} image={image} date={date} />

                                    </Grid>
                                )
                            }
                            </Grid>
                        </TabPanel>

                        <TabPanel value="3" sx={{ width: '100%' }}>
                            <Grid container spacing={3} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 200; target: > div;">
                            {
                                Others.map( ({ title, embedID, image }, index) => 
                                    <Grid item xs={12} md={6} xl={4} key={index}>

                                        <SingleOther title={title} embedID={embedID} image={image} />

                                    </Grid>
                                )
                            }
                            </Grid>
                        </TabPanel>

                    </Box>
                </Container>

            </TabContext>

        </Paper>
        </>
    );
}
