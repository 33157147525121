import { Box, Container, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReactTypingEffect from 'react-typing-effect';

import CloseIcon from '@mui/icons-material/KeyboardBackspace';
import IconButton from '@mui/material/IconButton';

import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Prestation from "../assets/images/fans2.jpeg";
import CardMembershipIcon from '@mui/icons-material/CardMembership';

const UpperBar = () => {

    const [open, setOpen] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const CssTextField = styled(TextField)({
        '& label': {
            color: 'black',
            fontFamily: 'Roboto',
        },
        '& label.Mui-focused': {
            color: '#d2ac47',
            fontWeight: 'bold',
            fontFamily: 'Roboto',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#d2ac47',
        },

    });


    return (
        <Stack position="relative" sx={{ background: "linear-gradient(to left, #ae8625, #f7e78a, #d2ac47, #edc967);", transition: "all 1s", width: '100%' }}>
            <Container sx={{ maxWidth: { lg: "lg", xl: "xl" } }}>

                <Box sx={{ display: 'flex', mt: 0.3, mb: 0.3 }} justifyContent="space-between" alignItems="center">

                    <Box sx={{ display: 'flex', }} gap={1}>

                        <MailOutlineIcon fontSize="small" sx={{ color: "black" }} />
                        <TwitterIcon fontSize="small" sx={{ color: "black" }} />
                        <FacebookOutlinedIcon fontSize="small" sx={{ color: "black" }} />
                        <InstagramIcon fontSize="small" sx={{ color: "black" }} />

                    </Box>

                    <Box sx={{ display: 'flex', }} gap={2}>

                        <Typography component={Link} sx={{ display: { xs: 'none', lg: 'block' }, textDecoration: 'none', fontFamily: 'Roboto', textTransform: 'uppercase', fontSize: '0.85rem', color: "black", '&:hover': { textDecoration: 'none' } }} onClick={handleClickOpen}>
                            <ReactTypingEffect
                                text={["Inscrivez vous à notre newsletter et vous recevrez exclusivement toutes les nouveautés."]}
                                speed={90}
                                eraseSpeed={70}
                                eraseDelay={3500}
                                typingDelay={1000}
                            />
                        </Typography>

                        <Typography component={Link} sx={{ display: { xs: 'flex', lg: 'none' }, textDecoration: 'none', alignItems: 'center', flexWrap: 'wrap', '&:hover': { textDecoration: 'none' } }} gap={0.4} onClick={handleClickOpen}>
                            <CardMembershipIcon fontSize="small" sx={{ color: "black" }} />
                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '0.85rem', color: "black", textTransform: 'uppercase', }}>Newsletter</Typography>
                        </Typography>

                        <Dialog open={open} onClose={handleClose} fullScreen={fullScreen} maxWidth="lg" sx={{ borderRadius: 0 }}>


                            <Card sx={{ borderRadius: 0, display: "flex", flexDirection: "column", justifyContent: "center", m: 0, pl: 0, pr: 0, pt: 0, pb: 0 }}>

                                <Paper elevation={6} square sx={{ borderRadius: 0 }}>

                                    <Grid container>

                                        <Grid item xs={12} md={5} lg={6} sx={{}}>
                                            <Card sx={{ minHeight: { xs: '30vh', md: '65vh' }, }}>
                                                <CardCover sx={{ boxShadow: 'none', borderRadius: 0 }}>
                                                    <img src={Prestation} alt="" />
                                                    <CardCover sx={{ background: { xs: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0)) 50% , linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0) 200px)', md: 'rgba(0,0,0,.2)' }, borderRadius: 0, }} />
                                                </CardCover>
                                                <CardContent>
                                                    <IconButton onClick={handleClose} sx={{ display: {xs: 'block', md: 'none'}, position: 'absolute', left: '0%', pl:3 }} >
                                                        <CloseIcon sx={{ color: 'rgba(255,255,255,.6)', fontSize:'3.5rem', transition: 'color 1s', "&:hover": { color: 'white' } }} />
                                                    </IconButton>
                                                    <DialogTitle sx={{ display: {xs: 'block', md: 'none'}, typography: 'h4', fontWeight: 'bold', fontFamily: 'Roboto', color: '#d2ac47', mt: 7, }}>Newsletter</DialogTitle>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12} md={7} lg={6}>
                                            <Box sx={{ p: { xs: 2, md: 5.9 }, }} uk-scrollspy="cls: uk-animation-slide-right-small; delay: 200;">
                                                <DialogTitle sx={{ display: {xs: 'none', md: 'block'}, typography: 'h4', fontWeight: 'bold', fontFamily: 'Roboto', color: '#d2ac47', mt: 3, }}>Newsletter</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText sx={{ fontFamily: 'Roboto', color: 'black', pb: 2 }}>
                                                        En vous abonnant, vous recevrez régulièrement par e-mail des informations exclusives, des conseils utiles et des contenus pertinents directement dans votre boîte de réception. Vous serez ainsi toujours au courant des derniers articles, des événements à venir et des promotions spéciales réservées aux abonnés.
                                                    </DialogContentText>
                                                    <Grid container spacing={3}>

                                                        <Grid item xs={12} md={5}>
                                                            <CssTextField fullWidth id="standard-basic" label="Nom" variant="standard" required />
                                                        </Grid>
                                                        <Grid item xs={12} md={7}>
                                                            <CssTextField fullWidth id="standard-basic" label="Prénom" variant="standard" required />
                                                        </Grid>
                                                        <Grid item xs={12} md={7}>
                                                            <CssTextField fullWidth id="standard-basic" label="E-mail" variant="standard" required type="email" />
                                                        </Grid>
                                                        <Grid item xs={12} md={5}>
                                                            <CssTextField fullWidth id="standard-basic" label="Number" variant="standard" required type="number" />
                                                        </Grid>


                                                    </Grid>
                                                </DialogContent>
                                                <DialogActions sx={{}}>
                                                    <Button sx={{ button: { xs: 'small', md: 'medium' }, backgroundColor: 'transparent', borderColor: 'black', borderRadius: '0px', color: 'black', justifyContent: 'flex-end', fontFamily: 'Roboto', "&:hover": { color: '#d2ac47', borderColor: '#d2ac47', backgroundColor: 'transparent', } }} onClick={handleClose}>Annuler</Button>
                                                    <Button sx={{ button: { xs: 'small', md: 'medium' }, backgroundColor: '#d2ac47', border: '1px solid', borderColor: 'white', borderRadius: '0px', color: 'white', justifyContent: 'flex-end', fontFamily: 'Roboto', "&:hover": { color: '#d2ac47', borderColor: '#d2ac47', } }} >S'inscrire</Button>

                                                </DialogActions>
                                            </Box>
                                        </Grid>



                                    </Grid>
                                </Paper>

                            </Card>



                        </Dialog>

                        <Tooltip title="Votre panier est vide." placement="bottom">
                            <Typography component={Link} sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', flexWrap: 'wrap', '&:hover': { textDecoration: 'none' } }} gap={0.4}>
                                <ShoppingCartIcon fontSize="small" sx={{ color: "black" }} />
                                <Typography sx={{ display: { xs: 'none', md: 'block' }, fontSize: '0.85rem', color: "black", fontFamily: 'Roboto', textTransform: 'uppercase' }}>Panier</Typography>
                            </Typography>
                        </Tooltip>

                    </Box>

                </Box>

            </Container>
        </Stack>
    );
};

export default UpperBar;
