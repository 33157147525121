import { Paper, Typography, Grid, Container } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import Prestation from "../assets/images/cover.jpg";
import G from "../assets/images/gp7.gif";
import { Helmet } from 'react-helmet-async';



const HomePage = () => {
    
    

    return (
        <>
            <Helmet>
                <title>Faramareen Music Group</title>
                <meta name='description' content="Site officiel de Faramareen Music Group. Une maison de production musicale indépendante et globalement reconnue."></meta>
                <meta name='keywords' content="Music, Audio, Faramareen, Wally, Seck"></meta>
            </Helmet>

            <Paper variant="outlined" square sx={{ backgroundColor:"black", width: '100vw', height: '100vh', overflow: 'hidden', border: 0, justifyContent: 'center', alignItems: 'center' }}>
            
                <Box sx={{ position: 'absolute', width: '100vw', height: '100vh', background: 'rgba(0,0,0,0.9)' }} />

                <Box sx={{ position: 'absolute', width: '100%', pt:16 }}>

                    <Container sx={{  }}>

                        <Typography uk-scrollspy="cls: uk-animation-slide-right-small; delay: 400" align="left" color="rgba(255,255,255,.5)" sx={{ typography: 'h3', letterSpacing:'.2vw', fontFamily: "Roboto", fontWeight: "bold" }}>NOUVEL</Typography>
                        <Typography uk-scrollspy="cls: uk-animation-slide-top-small; delay: 800" fontFamily="Roboto" fontWeight="bold" variant='h3' align="left" color="#fff" sx={{ letterSpacing:'.2vw', ml:10, display: 'inline-block', pb:7, }}>ALBUM</Typography>


                        <Grid container>

                            <Grid item xs={12} md={6}>
                                <img src={Prestation} alt="" uk-scrollspy="cls: uk-animation-slide-top-small; delay: 800" />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box sx={{ p:7 }}>
                                    <img src={G} alt="" uk-scrollspy="cls: uk-animation-fade; delay: 800" />
                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>            

            </Paper>
        </>
        
    );
};

export default HomePage;