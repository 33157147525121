import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import HomePage from './pages/HomePage';
import ServicePage from "./pages/ServicePage2";
import VideoPage from "./pages/VideoPage";
import StorePage from "./pages/StorePage";
import ArtistPage from "./pages/ArtistPage";

import './assets/kit/css/uikit.min.css';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from "react-router-dom";


const router = createBrowserRouter([

    {
        element: <App />,

        children: [

            {
                path: "/",
                element: <HomePage />,
            },
            {
                path: "store",
                element: <StorePage />,
            },
            {
                path: "services",
                element: <ServicePage />,
            },
            {
                path: "videos",
                element: <VideoPage />,
            },
            {
                path: "artists",
                element: <ArtistPage />,
            },
            
          ],
    },

]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <RouterProvider router={router} />
        </HelmetProvider>
    </React.StrictMode>
);




