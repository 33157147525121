import Wurus from "./imClips/wurus.png";
import Reuguinetass from "./imClips/reuguinetass.png";
import Mamacita from "./imClips/mamacita.png";
import Allah from "./imClips/allah.png";
import Iminlove from "./imClips/iminlove.png";
import Taxilove from "./imClips/taxilove.png";
import Dafmaydal from "./imClips/dafmaydal.png";
import Jfete from "./imClips/jfete.png";
import Dawuma from "./imClips/dawuma.jpeg";
import Sexyboy from "./imClips/sexyboy.jpeg";
import Ndank from "./imClips/ndank.png";
import Whynot from "./imClips/whynot.png";
import Heyya from "./imClips/heyya.png";
import Waka from "./imClips/waka.png";
import Pce from "./imClips/pce.png";


const Clips = [

    {
        title: "Je ne suis pas comme eux",
        artist: "Wally B. Seck",
        embedID: "MObySsZo594",
        image: Pce,
    },

    {
        title: "Waka Waka",
        artist: "Wally B. Seck",
        embedID: "xqxLLFxFlpo",
        image: Waka,
    },

    {
        title: "Hey Ya",
        artist: "Wally B. Seck",
        embedID: "KHYf1NdNL74",
        image: Heyya,
    },

    {
        title: "Why Not",
        artist: "Wally B. Seck",
        embedID: "A-06gfQnhAs",
        image: Whynot,
    },

    {
        title: "Defma Ndank",
        artist: "Wally B. Seck",
        embedID: "4-pEjXxTuIc",
        image: Ndank,
    },

    {
        title: "Sexy Boy",
        artist: "Wally B. Seck",
        embedID: "fB2RyTVQVfQ",
        image: Sexyboy,
    },

    {
        title: "Dawuma Dara",
        artist: "Wally B. Seck Ft. Samba Peuzzi",
        embedID: "k3otOQd2T4U",
        image: Dawuma,
    },
    
    {
        title: "Jour de fête",
        artist: "Wally B. Seck Ft. Jason Derulo",
        embedID: "ZGgSgH19rgA",
        image: Jfete,
    },
    
    {
        title: "Wurus",
        artist: "Wally B. Seck",
        embedID: "9MrrNM8DICA",
        image: Wurus,
    },

    {
        
        title: "Reuguine Tass",
        artist: "Wally B. Seck Ft. Viviane",
        embedID: "cKduEJcGFwg",
        image: Reuguinetass,
    },

    {
        title: "Mamacita",
        artist: "Wally B. Seck",
        embedID: "GbbVVTIJlvY",
        image: Mamacita,
    },

    {
        
        title: "Thank You Allah",
        artist: "Wally B. Seck",
        embedID: "5lfDEJu3BOs",
        image: Allah,
    },

    {
        title: "I'm in love",
        artist: "Wally B. Seck",
        embedID: "oM9jD24d234",
        image: Iminlove,
    },

    {
        title: "Dafmay Dal",
        artist: "Wally B. Seck",
        embedID: "Uzhlt8MP0dE",
        image: Dafmaydal,
    },

    {
        
        title: "Taxi Love",
        artist: "Wally B. Seck",
        embedID: "9nlXKav24V8",
        image: Taxilove,
    },
        
]

export default Clips;