import * as React from 'react';
import { useState } from "react";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import { Button, } from '@mui/material';
import { Box } from '@mui/system';
import ImageListItem from '@mui/material/ImageListItem';


import '../assets/kit/fonts/Lost in Wild Regular.ttf';



const SingleArtistPhoto = (props) => {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function srcset(image, size, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${
                size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
        };
    }


    return (
        <>
            <ImageListItem component={Button} onClick={() => handleClickOpen()} key={props.img} cols={props.cols || 1} rows={props.rows || 1}>
                <img className='imgStl'
                    {...srcset(props.img, 300, props.rows, props.cols)}
                    alt={props.title}
                    loading="lazy"
                />
                
            </ImageListItem>
            
        

            <Dialog fullScreen open={open} onClose={handleClose} sx={{ background: 'black' }} >
                
                <Box sx={{ background: 'black', height: '100vh', width: '100vw' }}>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', bottom: '2.5%', left: '50%', transform: 'translate(-50%)' }}>
                        <CloseIcon sx={{ color: 'rgba(255,255,255,.3)', fontSize:'3rem', transition: 'color 1s', "&:hover": { color: 'white' } }} />
                    </IconButton>
                    <DialogContent >
                        <img className='imgSt'
                            src={props.img}
                            loading="lazy"
                            alt={props.img}
                            style={{ position: 'absolute', maxHeight: '80vh', maxWidth: '95vw', mx:2, transform: 'translate(-50%,-50%)', top: '50%', left: '50%',  }}
                        />
                    </DialogContent>
                </Box>
                
            </Dialog>
        </>
    );
};

export default SingleArtistPhoto;